<template>
    <section id="partners" class="container">
            <h2>Partnereink</h2>
            <div class="logos">
                <div class="logo-holder">
                    <a class="position-relative" href="https://bolt.hvg.hu/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/partner_1.png" :alt="partnerContents.alt1"/></a>
                </div>
                <div class="logo-holder">
                     <a class="position-relative" href="http://www.jpksz.hu/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/partner_2.png" :alt="partnerContents.alt2"/></a>
                </div>
                <div class="logo-holder">
                     <a class="position-relative" href="http://lkk.uni-sopron.hu/kezdolap" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/partner_3.png" :alt="partnerContents.alt3"/></a>
                </div>
                <div class="logo-holder">
                     <a class="position-relative" href="https://www.facebook.com/kaldorszakkoli/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/partner_4.png" :alt="partnerContents.alt4"/></a>
                </div>
                <div class="logo-holder">
                     <a class="position-relative" href="https://www.facebook.com/HantosElemerSzakkollegium/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/partner_5.png" :alt="partnerContents.alt5"/></a>
                </div>
                <div class="logo-holder">
                     <a class="position-relative" href="https://tarsasag-bce.pacioli.hu/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/partner_6.png" :alt="partnerContents.alt6"/></a>
                </div>
                <div class="logo-holder">
                     <a class="position-relative" href="https://www.facebook.com/pszkszakkollegium/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/partner_7.png" :alt="partnerContents.alt7"/></a>
                </div>
                <div class="logo-holder">
                     <a class="position-relative" href="https://www.facebook.com/dexmuhely" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/partner_8.png" :alt="partnerContents.alt8" /></a>
                </div>
                <div class="logo-holder">
                     <a class="position-relative" href="https://www.facebook.com/gundelszakkollegium/" target="_blank" rel="noopener"> <img class="partner-logo" src="../assets/partners/partner_9.png" :alt="partnerContents.alt9"/></a>
                </div>
            </div>
    </section>
</template>

<script>

export default{
  name:'Partners',
  props:['globalData'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][220]
    const partnerContents = contents.partners
    return{
      partnerContents
    }
  }
}
</script>

<style scoped>
#partners{
    margin-bottom: 4.55rem;
}
#partners img{
    border-radius: 0;
}
h2{
    text-align: center;
    margin-bottom: 2.27rem;
}
.logos{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1rem;
}
.logo-holder:nth-child(3){
    margin-right:18px;
}
.logo-holder:nth-child(4){
    margin-right:28px;
}
.logo-holder:nth-child(5){
    margin-right:24px;
}
.logo-holder:nth-child(6){
    margin-right:18px;
}
.logo-holder:nth-child(7){
    margin-right:5px;
}
@media (max-width: 576px){
    #partners{
        margin-bottom: 2.5rem;
    }
}
</style>