<template>
  <section id="cover">
    <div class="container">
      <div class="row">
        <div class="col-lg-9">
          <div v-html="coverContents.text"></div>
          <a href="https://myaccount.ecosim.hu/versenyek/diak_menedzsment_bajnoksag_2024" target="_blank" rel="noopener" class="btn btn-secondary">{{ coverContents.btn1 }}</a>
        </div>
        <div class="col-lg-3">
          <canvas id="mycanvas" class="absolute-img" width="91" height="91"></canvas>
          <img src="../assets/logo_loxon.png" alt="Loxon Solutions" width="116" height="23"/>
<!--          <img src="../assets/logo_exxon.png" alt="ExxonMobil"/>-->
          <div class="yellow-box">
            <div class="content">
              <div class="prizes"><strong class="text-uppercase">1. hely</strong><br><strong>210.000 Ft</strong></div>
              <i class="fa-solid fa-tv"></i>
            </div>
            <h4>Műszaki utalvány</h4>
          </div>
<!--          <img src="../assets/logo_loxon.png" alt="Loxon Solutions" width="116" height="23"/>-->
          <div class="yellow-box">
            <div class="content">
              <div class="prizes"><strong class="text-uppercase">2. hely</strong><br><strong>150.000 Ft</strong></div>
              <i class="fa-solid fa-tv"></i>
            </div>
            <h4>Műszaki utalvány</h4>
          </div>
<!--          <img src="../assets/logo_ps.png" alt="Process Solution"/>-->

          <div class="yellow-box">
            <div class="content">
              <div class="prizes"><strong class="text-uppercase">3. hely</strong><br><strong>90.000 Ft</strong></div>
              <i class="fa-solid fa-tv"></i>
            </div>
            <h4>Műszaki utalvány</h4>
          </div>
          <router-link class="btn btn-primary" :to="{name : 'Page_223'}">{{ coverContents.btn2 }}</router-link>
        </div>
      </div>
    </div>
  </section>
  <section id="simulation" class="container ">
    <div class="row">
      <div class="col-lg-6 order-lg-first order-last">
        <div class="img-holder">
          <img src="../assets/sim_1.jpg" alt="Szimuláció kép 1"/>
           <img src="../assets/sim_2.png" class="absolute-img" alt="Szimuláció kép 2"/>
        </div>
       
      </div>
      <div class="col-lg-6 order-lg-last order-first">
        <h2>{{simulationContents.title}}</h2>
        <div v-html="simulationContents.text"></div>
        <router-link class="btn btn-secondary" :to="{name : 'Page_221'}">{{simulationContents.btn}}</router-link>
      </div>
    </div>
  </section>
  <section id="timing">
    <h2>{{timingContents.title}}</h2>
    <div class="d-flex justify-content-center">
      <div class="timing-box">
        <i class="fa-solid fa-pen-to-square"></i>
        <p><strong>{{timingContents.text1}}</strong></p>
        <p>{{timingContents.deadline1}}</p>
      </div>
      <div class="timing-box">
        <i class="fa-solid fa-square-check"></i>
        <p><strong>{{timingContents.text2}}</strong></p>
        <p>{{timingContents.deadline2}}</p>
      </div>
      <div class="timing-box">
        <i class="fa-solid fa-location-dot"></i>
        <p><strong>{{timingContents.text3}}</strong></p>
        <p>{{timingContents.deadline3}}</p>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div class="timing-box">
        <i class="fa-solid fa-award"></i>
        <p><strong>{{timingContents.text4}}</strong></p>
        <p>{{timingContents.deadline4}}</p>
      </div>
      <div class="timing-box">
        <i class="fa-solid fa-trophy"></i>
        <p><strong>{{timingContents.text5}}</strong></p>
        <p>{{timingContents.deadline5}}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'MainPage',
  props:['globalData','pageId'],
  components: {
   
  },
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][this.pageId]
    const headData = contents.head
    const timingContents = contents.timing
    const simulationContents = contents.simulation
    const coverContents = contents.cover

    this.globalData.init.head.title = headData.title
    this.globalData.init.head.keywords = headData.keywords
    this.globalData.init.head.description = headData.description

    return{
      timingContents,
      simulationContents,
      coverContents
    }
  },
  watch:{
    pageId(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    },
    language(){
      this.contents = this.globalData.contents[this.language][this.pageId]
      this.globalData.init.head.title = this.contents.head.title
      this.globalData.init.head.keywords = this.contents.head.keywords
      this.globalData.init.head.description = this.contents.head.description
    }
  },
  mounted(){
    let canvas = document.querySelector("#mycanvas");
    let ctx = canvas.getContext('2d');
    let radius = (canvas.width * 0.9556) / 2;
    let amp = radius * 0.04444;
    let cx = radius + amp;
    let cy = radius + amp;        
    let sineCount=12;

    //draw circle with sine wave border
    ctx.fillStyle = "#959798";
    ctx.beginPath();
    for(let i=0;i<360;i++){
      let angle=i*Math.PI/180;
      let pt=sineCircleXYatAngle(cx,cy,radius,amp,angle,sineCount);
      ctx.lineTo(pt.x,pt.y);
    }
    ctx.closePath();
    ctx.fill();

    //draw white circle inside stamp
    ctx.lineWidth = radius / 20;
    ctx.strokeStyle = "#FFFFFF";
    ctx.beginPath();
    ctx.arc(cx, cy, radius * 0.85, 0, 2 * Math.PI);
    ctx.stroke();

    //draw text
    ctx.fillStyle = "#FFFFFF";
    ctx.textAlign = "center";
    let fontSizeBig = radius / 2.75;
    let fontSizeSmall = radius / 5;

    ctx.font = "bold " + fontSizeBig + "px Montserrat, sans-serif";
    ctx.fillText("EZÜST", cx, cy);

    ctx.font = fontSizeSmall + "px Montserrat, sans-serif";
    ctx.fillText("TÁMOGATÓ", cx, cy + fontSizeSmall * 1.25);
    
    function sineCircleXYatAngle(cx,cy,radius,amplitude,angle,sineCount){
        let x = cx+(radius+amplitude*Math.sin(sineCount*angle))*Math.cos(angle);
        let y = cy+(radius+amplitude*Math.sin(sineCount*angle))*Math.sin(angle);
        return({x:x,y:y});
    }
  }
}
</script>
<style>
#cover{
  background: url('../assets/main_cover.jpg') center center no-repeat;
  background-size: cover;
  padding-top: 1.41rem;
  padding-bottom: .45rem;
  margin-bottom: 4.55rem;
}
#cover h2{
  max-width: 26.23rem;
  margin-top: 3.59rem;
  font-size: 2.73rem;
  line-height: 3.32rem;
}
#cover .col-lg-3{
  background: #FFFFFF;
  text-align: center;
  padding-top: .82rem;
  padding-bottom: .91rem;
  border-radius: 5px;
  position: relative;
  margin-bottom: 2rem;
}
#cover .col-lg-3 img{
  margin-bottom: 1rem;
  max-width: 100%;
}
#cover .col-lg-3 .absolute-img{
  position: absolute;
  left: -49px;
  top: -11px;
  width: 91px;
  height: 91px;
  transform: rotate(-15deg);
}
#cover .yellow-box{
  background-color: #FCB925;
  border-radius: 10px;
  width: 185px;
  height: 108px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 1rem;
}
#cover .yellow-box .content{
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: .45rem;
}
#cover .yellow-box .prizes{
  text-align: center;
  color: #000000;
  font-size: .727rem;
}
#cover .yellow-box .text-uppercase{
  text-align: center;
  color: #FFFFFF;
}
#cover .yellow-box i{
  color: #FFFFFF;
  font-size:2.5rem;
}
#cover .yellow-box h4{
  border-radius: 10px;
  background-color: #06ABCE;
  color: #FFFFFF;
  font-size: .818rem;
  line-height: 1.272rem;
  margin-bottom: 0;
}
#simulation{
  margin-bottom: 9.1rem;
}
#simulation .row{
  row-gap: 1rem;
}
#simulation img{
  max-width: 100%;
  border-radius: 14px;
}
#simulation .img-holder{
  position: relative;
  margin-right: 4.45rem;
  width:fit-content;
}
#simulation .img-holder .absolute-img{
  position: absolute;
  bottom: -4.55rem;
  right: -4.45rem;
}
#timing{
  background: #FCB925;
  color:#FFFDFD;
  padding-top: 4.55rem;
  padding-bottom:5.6rem;
  margin-bottom: 4.55rem;
}
#timing h2{
  margin-bottom: 2.86rem;
  color:#FFFDFD;
  text-align: center;
}
.timing-box{
  border: 1px solid #FFFFFF;
  border-radius: 15px;
  width: 10.72rem;
  height: 10.72rem;
  margin: .45rem;
  padding-top: 1.82rem;
  text-align: center;
}
.timing-box i{
  margin-bottom: .82rem;
  font-size: 77px;
}
.timing-box p{
  line-height: 26.82px;
  margin-bottom: 0;
}
@media (max-width: 991px){
  #cover .row{
    row-gap: 1rem;
  }
  #cover .col-lg-3{
    align-self: center;
  }
  #cover .col-lg-3 .absolute-img{
      left: -36px;
    top: -6px;
  }
  #cover .btn-primary{
    padding: .556rem 1.1rem;
  }
}
@media (max-width: 767px){
  .col-lg-9{
    margin-bottom: 1rem;
  }
  #cover{
    padding-bottom:0;
  }
  #cover .col-lg-3{
    margin: auto;
  }
  #cover .col-lg-3 .absolute-img{
    left: -15px;
    top: -15px;
  }
  #timing .d-flex{
    flex-wrap: wrap;
  }
}
@media (max-width: 576px){
  #cover, #timing{
    margin-bottom: 1.55rem;
  }
  #cover h2{
    margin-top: 0.59rem;
    font-size: 1.7rem;
    line-height: 2rem;
  }
  #simulation{
    margin-bottom: 7rem;
  }
}
</style>
