<template>
  <header class="header">
    <div class="header-top">
        <div class="container d-flex justify-content-between">
            <router-link class="logo " :to="{name : 'Page_220'}">
              <img src="../assets/DMB_logo.png" alt="DMB logó">
            </router-link>
            <div class="image-holder">
<!--              <img src="../assets/logo_exxon.png" alt="ExxonMobil"/>-->
              <img src="../assets/logo_loxon.png" alt="Loxon Solutions" width="116" height="23"/>
<!--              <img src="../assets/logo_ps.png" alt="Process Solution"/>-->
            </div>
            <!-- <span class="header-title">{{headerContents.title}}</span> -->
            <div class="btn-holder">
              <a class="btn btn-primary" href="https://edu.ecosim.hu/#login" target="_blank" rel="noopener">{{headerContents.btn1}}</a>
              <a href="https://myaccount.ecosim.hu/versenyek/diak_menedzsment_bajnoksag_2024" target="_blank" rel="noopener" class="btn btn-primary">{{headerContents.btn2}}</a>
            </div>
        </div>
      </div>
    <nav class="navbar navbar-expand-lg navbar-light d-flex justify-content-between container">
      <button class="navbar-toggler  ms-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarList" aria-controls="navbarList" aria-expanded="true" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>
        <div class="collapse navbar-collapse" id="navbarList">
          <ul class="navbar-nav ms-auto">
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_221'}">{{headerContents.menuItem1}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_222'}">{{headerContents.menuItem2}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_223'}">{{headerContents.menuItem3}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_232'}">{{headerContents.menuItem9}}</router-link>
            </li>
<!--            <li class="nav-item dropdown">-->
<!--              <a class="nav-link dropdown-toggle" type="button" id="dropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">{{headerContents.menuItem4}}</a>-->
<!--              <div class="dropdown-menu dropdown-menu-center" aria-labelledby="dropdownMenu">-->
<!--                <router-link class="dropdown-item" :to="{name : 'Page_231'}">{{headerContents.menuItem8}}</router-link>-->
<!--                <router-link class="dropdown-item" :to="{name : 'Page_232'}">{{headerContents.menuItem9}}</router-link>-->
<!--                <router-link class="dropdown-item" :to="{name : 'Page_233'}">{{headerContents.menuItem10}}</router-link>-->
<!--              </div>-->
<!--            </li>-->
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_224'}">{{headerContents.menuItem5}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_225'}">{{headerContents.menuItem7}}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" :to="{name : 'Page_230'}">{{headerContents.menuItem6}}</router-link>
            </li>
          </ul>
          <div class="nav-btn-holder">
          <a class="btn btn-primary" href="" target="_blank" rel="noopener">{{headerContents.btn1}}</a>
          <a href="https://myaccount.ecosim.hu/versenyek/diak_menedzsment_bajnoksag_2024" target="_blank" rel="noopener" class="btn btn-primary">{{headerContents.btn2}}</a>
          </div>
        </div>

    </nav>
  </header>
</template>

<script>

export default{
  name:'Header',
  props:['globalData'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][220]
    const headerContents = contents.header
    return{
      headerContents
    }
  }
}
</script>

<style scoped>
.header{
  background-color:#ffffff;
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 3.333rem;
  z-index: 10;
}
.header-top{
  background: #F9F9F9;
  padding: 1rem;
}
.header-top .container{
  column-gap: .75rem;
  align-items: center;
}
/* .header-title{
  color: #000000;
  font-weight: 700;
  text-transform: uppercase;
  align-self: center;
} */
.image-holder{
  white-space: nowrap;
}
.image-holder img:not(img:last-child){
  margin-right: 1rem;
}
.navbar{
  padding-top: 1rem;
  padding-bottom: 1.6rem;
}
.navbar-light .navbar-nav .nav-link,.navbar-light .navbar-nav .nav-link:hover,.dropdown-item {
  color:#000000;
  font-size: .818rem;
  line-height:1rem;
}
.nav-btn-holder{
  display: none;
}
.dropdown-menu{
  border-radius: 0;
  min-width: 4rem;
}
.dropdown-item{
  padding-left: .5rem;
  padding-right: .5rem;
}
.btn-holder .btn{
  margin-left: .727rem;
}
@media screen and (max-width: 991px){
  .header-top .container{
    padding-right: 5rem;
  }
  .btn-holder{
    display: none;
  }
  .nav-btn-holder{
    display: grid;
    width: fit-content;
    margin:auto;
  }
  .nav-btn-holder .btn{
    margin-bottom: .25rem;
  }
  .navbar{
    padding:0;
  }
  .navbar-toggler{
    position: absolute;
    top: -3.5rem;
    right: 1rem;
  }
  .navbar-collapse{
    text-align:center;
  }
  .dropdown-menu{
    border: none;
    text-align: center;
  }
  .header-title{
    padding-right: 5rem;
    padding-left: 1rem;
    text-align: center;
    margin:auto;
  }
}
@media (max-width: 768px){
  /* .header-title{
    font-size: .8rem;
  }  */
  .header-top .container{
    flex-direction: column;
    align-items: baseline;
    padding-right: 0;
    row-gap: .5rem;
  }
  .header-top .image-holder{
    margin: auto;
  }
  .navbar-toggler{
    top: -6rem;
  }
}
@media (max-width: 576px){
   .header-top .image-holder{
     display: none;
   }
    .navbar-toggler{
    top: -3.5rem;
  }
}
</style>