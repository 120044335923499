<template>
  <footer>
    <div class="container">
        <div class="d-flex footer-menu">
            <ul class="list-inline mx-auto nav">
                <li class="nav-item">
                    <router-link :to="{name : 'Page_226'}">{{footerContents.menuItem1}}</router-link>
                </li>
                <li>I</li>
                <li class="nav-item">
                    <router-link :to="{name : 'Page_227'}">{{footerContents.menuItem2}}</router-link>
                </li>
            </ul>
        </div>
        <div>
             <a href="https://www.facebook.com/diakverseny" target="_blank" rel="noopener"><i class="fab fa-facebook"></i></a>
        </div>
    </div>
</footer>
</template>

<script>

export default{
  name:'Footer',
  props:['globalData'],
  data(){
    const language = this.globalData.init.language
    const contents = this.globalData.contents[language][220]
    const footerContents = contents.footer
    return{
      footerContents
    }
  }
}
</script>

<style scoped>
footer{
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #282828;
    color: #FFFEFE;
    text-transform: uppercase;
    text-align: center;
}
footer .container{
    margin: auto;
    padding-top:49px;
    padding-bottom: 37px;
}
footer a{
    padding: 14px;
    color: #FFFEFE;
    text-decoration: none;
}
footer a:hover{
    color:#2596be;
}
footer i{
    margin-top: 17px;
    color:#2596be;
    font-size: 43px;
}
@media (max-width: 768px){
    footer .list-inline{
        display: block;
    }
    footer li:not(li.nav-item){
        display: none;
    }
}
@media (max-width: 576px){
    footer{
        font-size: 16px;
    }
    footer .container{
       padding-top: 30px;
        padding-bottom: 20px; 
    }
}
</style>